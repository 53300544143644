import "./navbar.scss";



const Navbar = () => {
  return (
    <div className='navbar'>
        {/* <DialerSipOutlinedIcon /> */}
        <div className="container">

                <div className="logcontainer">

                <div className="logItem">SAN FRANCISCO NOTARY PUBLIC </div>

            </div>

            <div className="logRight">
                <div className="conatactYelp">
                    <a href="https://www.yelp.com/biz/priti-parikh-sf-sunset-notary-san-francisco-2">
                    <img src={process.env.PUBLIC_URL + '/Imgs/yelp.png'} 
                    
                    alt="" 
                    className="yelpimg"/></a>
                </div>
                <div className="contact">
              
                CALL US:  (415) 350-5655
                </div>
            </div>

        </div>
        
     
    </div>
  )
}

export default Navbar;