import "./footer.scss"

const Footer = () => {
  return (
    <div className='footerContainer'>
      <a href="https://wa.me/+14153505655" class="whatsapp_float" > <i class="fa fa-whatsapp whatsapp-icon"></i></a>
    </div>
  )
}

export default Footer;