import React from 'react';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import "./card.scss";
// import location from "../../image/location1.jpg";
// import priti from "../../image/priti.jpg";
// import singed from "../../image/signed.jpg";
// import services from "../../image/services.jpg";


const Cards = () => {
  return (
    <div className='cardContainer'>

       <div className="article">
         <img src={process.env.PUBLIC_URL + '/Imgs/priti.jpg'} alt="" />
            <div className="cardText">
                <h3>PRITI PARIKH</h3>
                Need a Quick Notary?. Please call Priti Parikh Notary Public at 415-350-5655 Also, a mobile notary, will travel to your location to notarize your documents if you can't come to me. 
                    <br/>

                <b>WORKING HRS : MONDAY- FRIDAY,</b>
                <b> SATURDAY & SUNDAY BY APPOINTMENT</b>
               </div>
         </div>


         <div className="article">
         <img src={process.env.PUBLIC_URL + '/Imgs/signed.jpg'} alt="" />
            <div className="cardText">
                <h3>RATED BEST NOTARY</h3>
                Highly Recommended Across the WEB. Number one Advance Notary in San Francisco
                    <br/>
                   

               
               </div>
         </div>


         <div className="article">
         <img src={process.env.PUBLIC_URL + '/Imgs/services.jpg'} alt="" />
            <div className="cardText">
                <h3>Services</h3>
                Copy Certifications, Consent for Minor to Travel
WIlls, Deeds, Domestic Partner Agreements
Divorce and Prenuptial Agreements, Power of Attorney
Personal Legal Documents, Judgment & Stipulations
Trusts & Trust Certifications, Release / Settlement Agreements
Advance Healthcare Directive, Affidavits/Oaths
Agreements, Condominium Conversions, Contracts
                    <br/>
                    
                     
                     {/* <a href="https://goo.gl/maps/Sjh3kNcfjm7upgst7" style="color: rgb(83, 177, 235);" > Map & Directions</a> */}

               
               </div>
         </div>

         <div className="article">
         <img src={process.env.PUBLIC_URL + '/Imgs/location1.jpg'} alt="" />
            <div className="cardText">
                <h3>location</h3>
                Located in 1315 7th Aves,San Francisco, CA 94122
                    <br/>
                    <a href="https://goo.gl/maps/JWumnJzJKojsS2JJ6">Map & Directions </a>
                    
                     
                     {/* <a href="https://goo.gl/maps/Sjh3kNcfjm7upgst7" style="color: rgb(83, 177, 235);" > Map & Directions</a> */}
                     {/* https://goo.gl/maps/JWumnJzJKojsS2JJ6 */}
               
               </div>
         </div>

        </div>
  )
}

export default Cards