
import './app.scss';

import Card from "./components/card/Cards";
import Navbar from "./components/navbar/Navbar";
import Footer from './components/photo/Footer';

import Slider from './components/Slider/Slider';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Slider />
      <Card/>
      <Footer />
      
    </div>
  );
}

export default App;
